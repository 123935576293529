import { render, staticRenderFns } from "./LanguageSwitcher.vue?vue&type=template&id=07ebdca2&scoped=true&"
import script from "./LanguageSwitcher.vue?vue&type=script&lang=js&"
export * from "./LanguageSwitcher.vue?vue&type=script&lang=js&"
import style0 from "./LanguageSwitcher.vue?vue&type=style&index=0&id=07ebdca2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07ebdca2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiSelect: require('/var/teamcity-builds/Omara_Staging_Builds_Frontend/components/UI/UiSelect.vue').default})
